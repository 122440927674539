import { Alert } from 'components/Layout';
import { useIntl } from 'react-intl';
import { ICON_NAMES } from '../../../constants/icon';
import { MAX_AMOUNT_OF_WISHLISTS } from '../../../constants/wishlist';
import { useAllWishlist } from '../../../features/wishlist/queries';
import Box from '../../Box/Box';
import Button from '../../Button/Button';
import Flex from '../../Flex/Flex';
import Loader from '../../Layout/Loader/Loader';
import Typography from '../../Typography/Typography';
import WishListOverviewItem from '../WishlistOverviewItem/WishlistOverviewItem';
import {
  StyledAddNewWishlistDescription,
  StyledAddNewWishlistSection,
  StyledDescriptionAndButtonWrapper,
} from './WishlistOverview.styled';

interface WishListOverviewProps {
  className?: string;
  openAddWishlistModal?: () => void;
}

const WishListOverview = ({ className, openAddWishlistModal }: WishListOverviewProps) => {
  const { formatMessage } = useIntl();

  const { data: activeWishlist, isLoading } = useAllWishlist();

  const wishlists = activeWishlist?.wishlists;

  return (
    <Box className={className} marginTop={200} position="relative">
      <Loader position="absolute" show={isLoading} />
      <Flex flexDirection="column" gap={200}>
        {!wishlists?.length ? (
          <Alert>{formatMessage({ id: 'wishlist_no_available_list_to_add_product' })}</Alert>
        ) : (
          wishlists.map((wishlist, index) => (
            <WishListOverviewItem key={wishlist.code} index={index} wishlist={wishlist} />
          ))
        )}

        {!((wishlists?.length ?? 0) >= MAX_AMOUNT_OF_WISHLISTS) && (
          <StyledAddNewWishlistSection borderRadius={50} padding={150}>
            <Typography fontWeight="bold" type="p">
              {formatMessage({ id: 'wish_list_add_wish_list_button' })}
            </Typography>

            <StyledDescriptionAndButtonWrapper>
              <StyledAddNewWishlistDescription marginBottom={0} type="p">
                {formatMessage({
                  id: 'wish_list_page_info_text',
                })}
              </StyledAddNewWishlistDescription>

              <Flex alignSelf="flex-end" marginTop={150}>
                <Button
                  color="secondary-alt"
                  icon={ICON_NAMES.PLUS}
                  iconPosition="before"
                  onClick={openAddWishlistModal}
                  outlined
                >
                  {formatMessage({
                    id: 'my_account_offline_invoice_add_button',
                  })}
                </Button>
              </Flex>
            </StyledDescriptionAndButtonWrapper>
          </StyledAddNewWishlistSection>
        )}
      </Flex>
    </Box>
  );
};

export default WishListOverview;
