import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import { useRouterParam } from 'hooks/useRouterParam';
import { useCookies } from 'react-cookie';
import { wishlistKeys } from '.';
import config from '../../config';
import {
  fetchWishlist,
  fetchWishlistProducts,
  fetchWishlistProductsByCodes,
  fetchWishlistRealtimeInfo,
  fetchWishlistShareLinks,
} from './connector';

const useAllWishlist = () => {
  const { locale } = useRouter();
  const { anonymous } = useAuthResolver();

  return useQuery({
    enabled: !anonymous,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWishlist({ locale }),
    queryKey: wishlistKeys.wishlist({ locale }),
    refetchOnWindowFocus: false,
  });
};

const useWishlistDetail = (wishlistId?: string, productCode?: string) => {
  const {
    locale,
    query: { id: queryId },
  } = useRouter();
  const id = Array.isArray(queryId) ? queryId[0] : queryId;

  return useQuery({
    enabled: !!id || !!wishlistId,
    queryFn: () => fetchWishlist({ locale, productCode, wishlistId: wishlistId || id }),
    queryKey: wishlistKeys.wishlist({ locale, productCode, wishlistId: wishlistId || id }),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useWishlistProducts = (wishlistId?: string) => {
  const { locale } = useRouter();
  const idParam = useRouterParam('id');
  const id = wishlistId ?? idParam ?? '';
  const currentPageParam = useRouterParam('currentPage');
  const currentPage = currentPageParam ? parseInt(currentPageParam) : 0;

  return useQuery({
    enabled: !!id,
    placeholderData: keepPreviousData,
    queryFn: id ? () => fetchWishlistProducts({ currentPage, locale, wishlistId: id }) : skipToken,
    queryKey: wishlistKeys.wishlistProducts({ currentPage, locale, wishlistId: id }),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useWishlistShareLinks = ({ enabled = false }) => {
  const { locale } = useRouter();
  const wishlistId = useRouterParam('id') ?? '';

  return useQuery({
    enabled: !!wishlistId && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWishlistShareLinks({ locale, wishlistId }),
    queryKey: wishlistKeys.wishlistSharingLinks({ locale, wishlistId }),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useAnonymousWishlistProducts = () => {
  const { locale } = useRouter();
  const [cookies] = useCookies();
  const productCodes = (cookies[config.wishListCookie.key] as string[] | undefined) ?? [];

  return useQuery({
    enabled: productCodes !== undefined,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWishlistProductsByCodes({ locale, productCodes }),
    queryKey: wishlistKeys.wishlistProductsByCodes({ locale, productCodes }),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useWishlistRealtimeInfo = (productCodes: string[]) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: !!productCodes.length,
    queryFn: () => fetchWishlistRealtimeInfo({ locale, productCodes }),
    queryKey: wishlistKeys.wishlistsRealtimeInfoCodes({ locale, productCodes }),
  });
};

export {
  useAllWishlist,
  useAnonymousWishlistProducts,
  useWishlistDetail,
  useWishlistProducts,
  useWishlistRealtimeInfo,
  useWishlistShareLinks,
};
