import { Spacer } from 'constants/styling';
import styled from 'styled-components';
import { spacer } from 'theme';

interface StyledTileTitleWrapperProps {
  marginBottom: Spacer;
  marginTop?: Spacer;
}

export const StyledTileTitleWrapper = styled.div<StyledTileTitleWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: ${spacer(25)};
  margin-bottom: ${({ marginBottom }) => spacer(marginBottom)};
  margin-top: ${({ marginTop }) => spacer(marginTop)};
`;
