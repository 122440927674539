import styled from 'styled-components';
import { color } from 'theme';
import { BoxColored } from '../../BoxColored/BoxColored.styled';
import Typography from '../../Typography/Typography';

export const StyledAddNewWishlistSection = styled(BoxColored)`
  border-left: 8px solid ${color('black')};
`;

export const StyledAddNewWishlistDescription = styled(Typography)`
  max-width: 100%;
`;

export const StyledDescriptionAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
