import { Modal } from 'components/Layout';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { getValidationSchema, validateForm } from '../../../utils/formUtil';
import { stringValidation } from '../../../utils/validation';
import Button from '../../Button/Button';
import FormGroupInput from '../../Form/FormGroupInput/FormGroupInput';

interface WishlistFormModalProps {
  buttonCopy?: string;
  callback: (values?: Record<string, string>) => void;
  closeModal: () => void;
  initialFormValues?: { [key: string]: unknown };
  show: boolean;
  side?: 'left' | 'right';
  title?: string;
}

const WishlistFormModal = ({
  buttonCopy,
  callback,
  closeModal,
  initialFormValues,
  show,
  side,
  title,
}: WishlistFormModalProps) => {
  const { formatMessage } = useIntl();

  if (!show) return null;

  return (
    <Modal className="flex flex-col gap-2" closeModal={closeModal} show={show} title={title} side={side}>
      <Form
        initialValues={{
          description: initialFormValues?.description || undefined,
          title: initialFormValues?.title || undefined,
        }}
        onSubmit={(values) => {
          callback(values);
          closeModal();
        }}
        render={({ handleSubmit, submitting }) => (
          <form id="edit-wishlist-modal-form" onSubmit={handleSubmit}>
            <FormGroupInput
              id="wishlist-title"
              label={formatMessage({
                id: 'wish_list_edit_form_title',
              })}
              name="title"
              type="text"
            />
            <FormGroupInput
              id="wishlist-description"
              label={formatMessage({
                id: 'wish_list_edit_form_description',
              })}
              name="description"
              type="textarea"
            />

            <Button disabled={submitting} fullwidth type="submit">
              {buttonCopy}
            </Button>
          </form>
        )}
        validate={validateForm(
          getValidationSchema({
            description: stringValidation(),
            title: stringValidation(),
          }),
        )}
      />
    </Modal>
  );
};

export default memo(WishlistFormModal, isEqual);
