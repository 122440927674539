import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactNode,
  Ref,
} from 'react';
import { Field } from 'react-final-form';
import { removeWhiteSpaces } from '../../../utils/textUtil';
import FormGroup from '../FormGroup/FormGroup';
import { StyledFormGroupType, StyledFromGroupResponse } from '../FormGroup/FormGroup.styled';
import FormLabel from '../FormLabel/FormLabel';
import { StyledAppendedContent, StyledInput, StyledPrependedContent } from './FormGroupInput.styled';

interface FormGroupInputProps {
  appendedContent?: ReactNode;
  className?: string;
  disabled?: boolean;
  handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  handleKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  label?: string;
  labelProps?: HTMLAttributes<HTMLLabelElement>;
  max?: number;
  min?: number;
  name: string;
  onClickAppendedContent?: () => void;
  onClickPrependedContent?: () => void;
  placeholder?: string;
  prependedContent?: ReactNode;
  required?: boolean;
  showError?: boolean;
  showValidationOnChange?: boolean;
  type?: string;
  validate?: () => void;
  value?: string;
}

const FormGroupInput = forwardRef(function formGroupInput(props: FormGroupInputProps, ref: Ref<HTMLInputElement>) {
  const {
    appendedContent,
    className = '',
    disabled,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyUp,
    id,
    inputProps,
    label,
    labelProps,
    max,
    min,
    name: inputName,
    onClickAppendedContent,
    onClickPrependedContent,
    placeholder,
    prependedContent,
    required,
    showError = true,
    showValidationOnChange,
    type: inputType = 'text',
    validate,
    value: inputValue,
  } = props;

  return (
    <Field name={inputName} type={inputType} validate={validate} value={inputValue}>
      {({ input, meta }) => {
        const { checked, name, onBlur, onChange, onFocus, type, value } = input;
        const { active, dirty, error, initial, invalid, submitFailed, valid } = meta;

        const showValidation = showValidationOnChange || submitFailed;

        const isActive = active ? 'active' : '';
        const isInitial = value && initial ? 'initial' : '';
        const isValid = showValidation && value && valid ? 'valid' : '';
        const isInvalid = showValidation && invalid ? 'invalid' : '';
        const isDirty = dirty ? 'dirty' : '';
        const hasAppendedContent = appendedContent ? 'appended' : '';
        const hasPrependedContent = prependedContent ? 'prepended' : '';

        return (
          <FormGroup
            className={removeWhiteSpaces(
              `${className} ${isDirty} ${isActive} ${isInitial} ${isInvalid} ${isValid} ${hasAppendedContent} ${hasPrependedContent}`,
            )}
          >
            <StyledFormGroupType className={`formGroup-type-${type}`}>
              <StyledInput
                ref={ref}
                autoComplete="off"
                checked={checked}
                className="formGroup-input"
                disabled={disabled}
                id={id || name}
                max={max}
                min={min}
                name={name}
                onBlur={(event: FocusEvent<HTMLInputElement>) => {
                  onBlur(event);
                  if (handleBlur) {
                    handleBlur(event);
                  }
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  type === 'file' ? onChange(event.target.files) : onChange(event);
                  if (handleChange) {
                    handleChange(event);
                  }
                }}
                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                  onFocus(event);
                  if (handleFocus) {
                    handleFocus(event);
                  }
                }}
                onKeyUp={handleKeyUp}
                placeholder={placeholder}
                required={required}
                type={type}
                value={type === 'file' ? undefined : value}
                {...inputProps}
              />

              {label && <FormLabel label={label} htmlFor={id || name} {...labelProps} />}

              {appendedContent && (
                <StyledAppendedContent onClick={onClickAppendedContent} className="formGroup-appended">
                  {appendedContent}
                </StyledAppendedContent>
              )}

              {prependedContent && (
                <StyledPrependedContent onClick={onClickPrependedContent} className="formGroup-prepended">
                  {prependedContent}
                </StyledPrependedContent>
              )}
            </StyledFormGroupType>

            {showValidation && error && showError && (
              <StyledFromGroupResponse className="formGroup-response">{error}</StyledFromGroupResponse>
            )}
          </FormGroup>
        );
      }}
    </Field>
  );
});

export default FormGroupInput;
