import { ReactNode } from 'react';
import { Color } from 'types/Color';
import { FontSize, FontTag, FontType, Spacer, TextAlign } from '../../../constants/styling';
import Flex from '../../Flex/Flex';
import Typography from '../../Typography/Typography';
import { StyledTileTitleWrapper } from './TileTitle.styled';

interface TileTitleProps {
  children: ReactNode;
  className?: string;
  eyebrow?: string;
  eyebrowColor?: Color;
  eyebrowMaxLines?: number;
  eyebrowSize?: FontSize;
  itemsColumn?: boolean;
  marginBottom?: Spacer;
  maxLines?: number;
  tag?: FontTag;
  textAlign?: TextAlign;
  titleSize?: FontSize;
  type?: FontType;
}

const TileTitle = ({
  children,
  className,
  eyebrow,
  eyebrowColor = 'primary-400',
  eyebrowMaxLines = 1,
  eyebrowSize = 100,
  itemsColumn = false,
  marginBottom = 0,
  maxLines = 3,
  tag = 'span',
  textAlign = 'left',
  titleSize = 125,
  type = 'h3',
}: TileTitleProps) => {
  if (itemsColumn) {
    return (
      <StyledTileTitleWrapper className={className} marginBottom={marginBottom} marginTop={eyebrow ? 0 : undefined}>
        {eyebrow && (
          <Typography
            color={eyebrowColor}
            fontSize={eyebrowSize}
            fontWeight="bold"
            marginBottom={0}
            maxLines={eyebrowMaxLines}
            textAlign={textAlign}
          >
            {eyebrow}
          </Typography>
        )}
        {children && (
          <Typography
            fontSize={titleSize}
            fontWeight="bold"
            marginBottom={0}
            marginTop={0}
            maxLines={maxLines}
            tag={tag}
            textAlign={textAlign}
            type={type}
          >
            {children}
          </Typography>
        )}
      </StyledTileTitleWrapper>
    );
  }

  return (
    <Flex className={className}>
      <Typography
        as={tag}
        fontSize={titleSize}
        fontWeight="bold"
        marginBottom={0}
        marginTop={0}
        maxLines={maxLines}
        textAlign={textAlign}
      >
        {typeof eyebrow === 'object' ? '' : eyebrow} {eyebrow && '|'} {children}
      </Typography>
    </Flex>
  );
};

export default TileTitle;
