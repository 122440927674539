import { Product } from 'types/Product';
import { cn } from 'utils/cn';
import Flex from '../Flex/Flex';
import Image from '../Image/Image';
import Price from '../Price/Price';
import ProductTitle from '../Product/ProductTitle/ProductTitle';
import { StyledProductPreviewImage, StyledProductPreviewInfo } from './ProductPreview.styled';

interface ProductPreviewProps {
  className?: string;
  product?: Product;
}

const ProductPreview = ({ className, product }: ProductPreviewProps) => {
  if (!product) {
    return null;
  }

  const imageUrl = product.images?.[0]?.url;

  return (
    <Flex alignItems="center" className={cn('product-preview', className)} fullWidth gap={100}>
      <StyledProductPreviewImage className="product-preview-image">
        {imageUrl && (
          <Image
            alt={product.name}
            layout="fill"
            objectFit="contain"
            objectPosition="top left"
            sizes={{
              default: 80,
            }}
            src={imageUrl}
          />
        )}
      </StyledProductPreviewImage>
      <StyledProductPreviewInfo className="product-preview-info">
        <ProductTitle isLink={false} product={product} />
        <Price currentPrice={product.price?.value ?? 0} fontSize={100} oldPrice={product?.strikePrice?.value} />
      </StyledProductPreviewInfo>
    </Flex>
  );
};

export default ProductPreview;
