import styled, { css } from 'styled-components';
import { color, spacer } from 'theme';
import { StylingFormGroupSelectAndInput } from '../FormGroup/FormGroup.styled';
import { HTMLAttributes } from 'react';

const StylingExtraContent = css<HTMLAttributes<HTMLSpanElement>>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const StyledPrependedContent = styled.span`
  ${StylingExtraContent}
  left: ${spacer(100)};
  bottom: ${spacer(75)};
`;

export const StyledAppendedContent = styled.span`
  ${StylingExtraContent}
  right: ${spacer(100)};
  bottom: ${spacer(75)};

  .search-icon {
    cursor: pointer;
  }

  .loading-icon {
    transform: rotate(90deg);
    width: 30px;
    fill: ${color('primary-300')};
  }
`;

export const StyledInput = styled.input`
  ${StylingFormGroupSelectAndInput}
`;
