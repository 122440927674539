import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import Typography from 'components/Typography/Typography';
import { ICON_NAMES } from 'constants/icon';
import { useMutateAddProductToWishlist, useMutateRemoveProductToWishlist } from 'features/wishlist/mutations';
import useRouter from 'hooks/useRouter';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useWishlistActions, useWishlistProduct } from 'store/wishlist';
import { Wishlist } from 'types/Wishlist';
import { useWishlistDetail } from '../../../features/wishlist/queries';
import { StyledWishlistItem } from './WishlistOverviewItem.styled';

interface WishListOverviewItemProps {
  index: number;
  wishlist: Wishlist;
}

const WishListOverviewItem = ({ index, wishlist }: WishListOverviewItemProps) => {
  const { locale } = useRouter();
  const { formatMessage } = useIntl();

  const wishlistProduct = useWishlistProduct();

  const { isSuccess, mutateAsync: addProductToWishlist } = useMutateAddProductToWishlist();
  const { mutateAsync: removeProductFromWishlist } = useMutateRemoveProductToWishlist();
  const { data: activeWishlist } = useWishlistDetail(wishlist?.code, wishlistProduct?.code);

  const { setShowWishlistSuccessMessage } = useWishlistActions();

  const alreadyInWishlist = activeWishlist?.inWishlist;

  const onClick = () => {
    if (!wishlistProduct?.code || !wishlist?.code) return;
    if (alreadyInWishlist) {
      removeProductFromWishlist({
        locale,
        productCode: wishlistProduct?.code,
        wishlistId: wishlist?.code,
      });

      setShowWishlistSuccessMessage(false);
    } else {
      addProductToWishlist({
        productCode: wishlistProduct?.code,
        wishlistId: wishlist?.code,
      });
    }
  };

  useEffect(() => {
    // Automatically add product to first wishlist available
    if (!wishlistProduct?.code || !wishlist?.code) return;
    if (!activeWishlist?.inWishlist && index === 0) {
      addProductToWishlist({
        productCode: wishlistProduct?.code,
        wishlistId: wishlist?.code,
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setShowWishlistSuccessMessage(true);
    }
  }, [isSuccess]);

  return (
    <StyledWishlistItem>
      <Flex flexDirection="column">
        <Typography color="" fontWeight="bold">
          {`${wishlist?.title} ${wishlist.size !== undefined ? `(${Number(wishlist.size)})` : ''}`}
        </Typography>
        <Typography fontSize={88}>{wishlist?.description}</Typography>
      </Flex>

      <Flex alignItems="center" gap={50} onClick={onClick} role="button" tabIndex={0}>
        {formatMessage({
          id: alreadyInWishlist ? 'wish_list_button_active_text' : 'wish_list_button_not_active_text',
        })}
        <Icon
          type="custom"
          color={alreadyInWishlist ? 'secondary-300' : 'black'}
          name={alreadyInWishlist ? ICON_NAMES.HEART_FULL : ICON_NAMES.HEART}
          size={125}
        />
      </Flex>
    </StyledWishlistItem>
  );
};

export default WishListOverviewItem;
