import { useMutation, useQueryClient } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { wishlistKeys } from '.';
import {
  addProductToWishlist,
  addWishlist,
  editWishlist,
  removeProductFromWishlist,
  removeWishlist,
  removeWishlistProduct,
} from './connector';

const useMutateEditWishlist = () => {
  const { locale } = useRouter();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editWishlist,
    onSuccess: ({ code }) => {
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlist({ locale, wishlistId: code }) });
    },
  });
};

const useMutateRemoveWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wishlist'] });
    },
  });
};

const useMutateRemoveProductFromWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeWishlistProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['wishlistProducts'],
      });
    },
  });
};

const useMutateAddWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['wishlist'] });
    },
  });
};

const useMutateAddProductToWishlist = () => {
  const { locale } = useRouter();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addProductToWishlist,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: wishlistKeys.wishlist({ locale, wishlistId: data.code }),
      });
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlistsRealtimeInfo() });
    },
  });
};

const useMutateRemoveProductToWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeProductFromWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['wishlist'],
      });
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlistsRealtimeInfo() });
    },
  });
};

export {
  useMutateAddProductToWishlist,
  useMutateAddWishlist,
  useMutateEditWishlist,
  useMutateRemoveProductFromWishlist,
  useMutateRemoveProductToWishlist,
  useMutateRemoveWishlist,
};
