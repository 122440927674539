import styled from 'styled-components';
import { spacer, variable } from 'theme';

export const StyledWishlistItem = styled.div`
  ${variable('border-bottom')}
  padding-bottom: ${spacer(200)};
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
`;
