import styled from 'styled-components';
import { spacer } from 'theme';

export const StyledProductPreviewImage = styled.div`
  flex: 1 1 auto;
  height: 80px;
  min-width: 80px;
  position: relative;
`;

export const StyledProductPreviewInfo = styled.div`
  flex: 1 1 auto;
  max-width: calc(100% - 80px);
  padding: 0px ${spacer(50)};
  display: flex;
  flex-direction: column;
  gap: ${spacer(100)};
`;
