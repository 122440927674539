import styled from 'styled-components';
import { color } from 'theme';
import { Color } from 'types/Color';
import Box, { BoxProps } from '../Box/Box';

export interface BoxColoredProps extends BoxProps {
  backgroundColor?: Color;
}

export const BoxColored = styled(Box)<BoxColoredProps>`
  background-color: ${({ backgroundColor = 'whisper-100' }) => color(backgroundColor)};
`;
