import type {
  FetchWishlistParams,
  FetchWishlistProductsByCodesParams,
  FetchWishlistProductsParams,
  FetchWishlistRealtimeInfoParams,
  FetchWishlistShareLinksParams,
} from './connector';

export const wishlistKeys = {
  wishlist: (params: FetchWishlistParams) => ['wishlist', params],
  wishlistProducts: (params: FetchWishlistProductsParams) => ['wishlistProducts', params],
  wishlistProductsByCodes: (params: FetchWishlistProductsByCodesParams) => ['wishlistProductsByCode', params],
  wishlistSharingLinks: (params: FetchWishlistShareLinksParams) => ['wishlistSharingOptions', params],
  wishlistsRealtimeInfo: () => ['wishlistsRealtimeInfo'],
  wishlistsRealtimeInfoCodes: (params: FetchWishlistRealtimeInfoParams) => [
    ...wishlistKeys.wishlistsRealtimeInfo(),
    params,
  ],
};
